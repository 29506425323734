/************ Custom Font ************/
@font-face {
    font-family: Centra;
    src: url('./assets/font/CentraNo2-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: Centra;
    src: url('./assets/font/CentraNo2-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: Centra;
    src: url('./assets/font/CentraNo2-Book.ttf');
    font-weight: 400;
}

/************ Default Css ************/
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
    scroll-padding-top: 75px;
}

body {
    font-weight: 400;
    overflow-x: hidden;
    position: relative;
    background-color: #121212 !important;
    color: #fff !important;
    font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    line-height: normal;
}

p, a, li, button, ul {
    margin: 0;
    padding: 0;
    line-height: normal;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

img {
    width: 100%;
    height: auto;
}

button {
    border: 0;
    background-color: transparent;
}



input:focus, textarea:focus, select:focus {
    outline: none;
}

@media (min-width: 1700px) {
    main .container {
        max-width: 100%;
        padding: 0 150px;
    }
}

p.success {
    color: green;
}

p.danger {
    color: red;
}

/************ Navbar Css ************/
nav.navbar {
    padding: 18px 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9999;
    transition: 0.32s ease-in-out;
    align-items: baseline;

}


nav.navbar.scrolled {
    padding: 0 0;
    background-color: #121212;
}

nav.navbar a.navbar-brand {
    width: 9%;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
    font-weight: 400;
    color: #fff !important;
    letter-spacing: 0.8px;
    padding: 0 25px;
    font-size: 13px;
    opacity: 0.75;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}

/*.logo{
    display: flex;
    align-items: center;
}
.ms-image {
    width: 50px;
    height:50px;
    margin-right: 50px;
}
.ms-logo {
    color: #fff;
    font-size: 26px;
}*/

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-container {
    width: 50px; /* Initial width */
    height: 50px; /* Initial height */
z-index: 1;
}

.ms-image {
    width: 50px; /* To fill the container */
    height: 50px; /* To fill the container */
    border-radius: 50%; /* Circular shape */
    transition: all 0.3s ease; /* Smooth transition effect */
    z-index: 1;
}

.image-container:hover{
    z-index: 2;
}

.image-container:hover .ms-image {
    width: 250px; /* Expanded width on hover */
    height: 250px; /* Expanded height on hover */
}

.image-container:hover .ms-logo {
    z-index: -1;
}

.ms-logo {
    margin-left: 10px; /* Adjust as needed */
    color: #fff;
    font-size: 26px;
}


span.navbar-text {
    display: flex;
    align-items: center;
}

.social-icon {
    display: inline-block;
    margin-left: 14px;
}

.social-icon a {
    width: 30px;
    height: 30px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
}

.social-icon a::before {
    content: "";
    width: 30px;
    height: 30px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
    transition: 0.3s ease-in-out;
}

.social-icon a:hover::before {
    transform: scale(1);
}

.social-icon a img {
    width: 50%;
    z-index: 1;
    transition: 0.3s ease-in-out;

    filter: invert(82%) sepia(98%) saturate(0%) hue-rotate(298deg) brightness(109%) contrast(102%);

}

.social-icon a:hover img {
    filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

.navbar-text button {
    font-weight: 700;
    color: #fff;
    border: 1px solid #fff;
    padding: 10px 34px;
    font-size: 13px;
    margin-left: 18px;
    position: relative;
    background-color: transparent;
    transition: 0.3s ease-in-out;
}

.navbar-text button span {
    z-index: 1;
}

.navbar-text button::before {
    content: "";
    width: 0%;
    height: 100%;
    position: absolute;
    background-color: #fff;
    left: 0;
    top: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}

.navbar-text button:hover {
    color: #121212;
}

.navbar-text button:hover::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
    outline: none;
    box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
    width: 24px;
    height: 17px;
    background-image: none;
    position: relative;
    border-bottom: 2px solid #fff;
    transition: all 300ms linear;
    top: -2px;
}

nav.navbar .navbar-toggler-icon:focus {
    border-bottom: 2px solid #fff;
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
    width: 24px;
    position: absolute;
    height: 2px;
    background-color: #fff;
    top: 0;
    left: 0;
    content: '';
    z-index: 2;
    transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
    top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
    transform: rotate(45deg);
    background-color: #fff;
    height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
    transform: translateY(8px) rotate(-45deg);
    background-color: #fff;
    height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
    border-color: transparent;
}

/************ Banner Css ************/
.banner {
    margin-top: 0;
    padding: 160px 0 130px 0;
    background-image: url('./assets/img/banner-bg.png');
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
}

.banner-row{
    align-items: center;
}

.banner .tagline {
    font-weight: 700;
    letter-spacing: 0.8px;
    padding: 8px 10px;
    background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
    border: 1px solid rgba(255, 255, 255, 0.5);
    font-size: 16px;
    margin-bottom: 16px;
    display: inline-block;
}

.banner h1 {
    font-size: 45px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1;
    margin-bottom: 20px;
    display: block;
}

.banner p {
    color: #B8B8B8;
    font-size: 14px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    width: 96%;
}

.banner button {
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    margin-top: 40px;
    letter-spacing: 0.8px;
    display: flex;
    align-items: center;
}

.banner_buttons {
    display: flex;

}

.download_cv_btn {
    margin-right:40px;
    font-weight: 700;
    letter-spacing: 1px;
    padding: 8px 10px;
    background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
    border: 2px solid rgba(255, 255, 255, 0.5);
}

.banner button svg {
    font-size: 25px;
    margin-left: 10px;
    transition: 0.3s ease-in-out;
    line-height: 1;
}

.banner button:hover svg {
    margin-left: 25px;
}

.banner img {
    width: 80%;
    height: 80%;
    animation: updown 3s linear infinite;
}

@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}

.txt-rotate > .wrap {
    border-right: 0.08em solid #666;
}

/************ Skills Css ************/
.skill {
    padding: 0 0 50px 0;
    position: relative;
}

.skill-bx {
    background: #151515;
    border-radius: 64px;
    text-align: center;
    padding: 50px 40px;
    margin-top: -40px;
}

.skill h2 {
    font-size: 45px;
    font-weight: 700;
}

.skill p {
    color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px 0 75px 0;
}

.skill-slider {
    width: 80%;
    margin: 0 auto;
    position: relative;
}

.skill-slider .item img {
    width: 50%;
    margin: 0 auto 15px auto;
}

.background-image-left {
    top: 28%;
    position: absolute;
    bottom: 0;
    width: 40%;
    z-index: -4;
}

/************ Projects Css ************/
.project {
    padding: 80px 0;
    position: relative;
    background-color: black;

}

.project h2 {
    font-size: 45px;
    font-weight: 700;
    text-align: center;
}

.project p {
    color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px auto 30px auto;
    text-align: center;
    width: 100%;
}

.project .nav.nav-pills {
    width: 72%;
    margin: 0 auto;
    border-radius: 50px;
    background-color: rgb(255 255 255 / 10%);
    overflow: hidden;
}

.project .nav.nav-pills .nav-item {
    width: 33.33333%;
}

.project .nav.nav-pills .nav-link {
    background-color: transparent;
    border-radius: 0;
    padding: 17px 0;
    color: #fff;
    width: 100%;
    font-size: 17px;
    letter-spacing: 0.8px;
    font-weight: 500;
    position: relative;
    transition: 0.3s ease-in-out;
    text-align: center;
    z-index: 0;
}

.project .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}

.project .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}

.project .nav.nav-pills .nav-link.active {
    border: 1px solid rgba(255, 255, 255, 1);
}

.nav-link#projects-tabs-tab-first {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 55px 0px 0px 55px;
}

.nav-link#projects-tabs-tab-second {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.nav-link#projects-tabs-tab-third {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 0 55px 55px 0;
}

/*project card*/
.proj-imgbx {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 24px;
}

.proj-imgbx::before {
    content: "";
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    opacity: 0.85;
    position: absolute;
    width: 100%;
    height: 0;
    transition: 0.4s ease-in-out;
}

.proj-imgbx:hover::before {
    height: 300px
}

.proj-title {
    position: absolute;
    text-align: center;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s ease-in-out;
    opacity: 1;
    width: 100%;
    color: #000000;
}


.proj-txtx {
    position: absolute;
    text-align: center;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s ease-in-out;
    opacity: 0;
    width: 100%;
}

.proj-imgbx:hover .proj-txtx {
    top: 50%;
    opacity: 1;
}

.proj-imgbx-image{
    height: 230px;
    object-fit: cover;
}
.proj-txtx h4 {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1.1em;
}

.proj-txtx span {
    font-style: italic;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.8px;
}

.background-image-right {
    top: 20%;
    position: absolute;
    bottom: 0;
    width: 35%;
    right: 0;
    z-index: -4;
}

/************ Projects Css ************/


/************ Venture Css ************/
.venture {
    padding: 80px 0;
    position: relative;
    background-color: black;

}

.venture h2 {
    font-size: 45px;
    font-weight: 700;
    text-align: center;
}

.venture p {
    color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px auto 30px auto;
    text-align: center;
    width: 100%;
}

.venture .nav.nav-pillss {
    width: 72%;
    margin: 0 auto;
    border-radius: 50px;
    background-color: rgb(255 255 255 / 10%);
    overflow: hidden;
}

.venture .nav.nav-pillss .nav-item {
    width: 50%;
}

.venture .nav.nav-pillss .nav-link {
    background-color: transparent;
    border-radius: 0;
    padding: 17px 0;
    color: #fff;
    width: 100%;
    font-size: 17px;
    letter-spacing: 0.8px;
    font-weight: 500;
    position: relative;
    transition: 0.3s ease-in-out;
    text-align: center;
    z-index: 0;
}

.venture .nav.nav-pillss .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}

.venture .nav.nav-pillss .nav-link.active::before {
    width: 100% !important;
}

.venture .nav.nav-pillss .nav-link.active {
    border: 1px solid rgba(255, 255, 255, 1);
}

.nav-link#venture-tabs-tab-first {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 55px 0px 0px 55px;
}



.nav-link#venture-tabs-tab-third {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 0 55px 55px 0;
}

.vent-imgbx {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 24px;
    height: 300px;
    background: #ffffff;
}

.vent-imgbx::before {
    content: "";
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    opacity: 0.85;
    position: absolute;
    width: 100%;
    height: 0;
    transition: 0.4s ease-in-out;
}

.vent-imgbx:hover::before {
    height: 300px
}

.vent-txtx {
    position: absolute;
    text-align: center;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s ease-in-out;
    opacity: 0;
    width: 100%;
}

.vent-title {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1.1em;
    position: absolute;
    text-align: center;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s ease-in-out;

    width: 100%;
    color: #000000;
}

.vent-imgbx:hover .vent-title {
    top: 0;
    opacity: 0;
}

.vent-imgbx:hover .vent-txtx {
    top: 50%;
    opacity: 1;
}

.vent-imgbx-image{
    height: 300px;
    object-fit: contain;
}
.vent-txtx h4 {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1.1em;
}

.vent-txtx span {
    font-style: italic;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.8px;
}

.background-image-right {
    top: 20%;
    position: absolute;
    bottom: 0;
    width: 35%;
    right: 0;
    z-index: -4;
}

/************ Ventures Css ************/

.contact {
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    padding: 60px 0 200px 0;
}

.contact img {
    width: 92%;
}

.contact h2 {
    font-size: 45px;
    font-weight: 700;
    margin-bottom: 30px;
}

.contact form input, .contact form textarea {
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    color: #fff;
    margin: 0 0 8px 0;
    padding: 18px 26px;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.8px;
    transition: 0.3s ease-in-out;
}

.contact form input:focus, .contact form textarea:focus {
    background: rgba(255, 255, 255, 1);
    color: #121212;
}

.contact form input::placeholder, .contact form textarea::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
}

.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
    color: #121212;
    opacity: 0.8;
}

.contact form button {
    font-weight: 700;
    color: #000;
    background-color: #fff;
    padding: 14px 48px;
    font-size: 18px;
    margin-top: 25px;
    border-radius: 0;
    position: relative;
    transition: 0.3s ease-in-out;
}

.contact form button span {
    z-index: 1;
    position: relative;
}

.contact form button:hover {
    color: #fff;
}

.contact form button::before {
    content: "";
    background: #121212;
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    transition: 0.3s ease-in-out;
}

.contact form button:hover::before {
    width: 100%;
}

/************ Time Line Css ************/
.time-line {
    width: 100%;
    height: 1060px;
    background-image: url('./assets/img/banner-bg.png');
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.curve {
    color: #fff;
    width: 100%;
    height: 1060px;
}

.cardinal-curve {
    stroke: #18a5e26e;
    stroke-width: 5;

}

.p-image {
    margin: 0;
    padding: 0;
    width: 100px;
    height: 100px;
    z-index: 1000;
    position: absolute;
}

.time-line-card {
    top: 0;
    position: absolute;
}

.time-line-card div h6{
    font-size: 16px;
}

.time-line-card div h6 span{
    font-size: 12px;
}
.time-line-card div p{
    font-size: 10px;
    color: #b0b0b0;
    font-weight: lighter;
}

#tl1{
    left: 350px;
    top: 950px;
}

#tl2{
    left: 550px;
    top: 860px;
}

#tl3{
    left: 650px;
    top: 780px;
}

#tl4{
    right: 450px;
    top: 660px;
}
#tl5{
    right: 310px;
    top: 570px;
}

#tl6{
    right: 250px;
    top: 420px;
}

#tl7{
    right: 150px;
    top: 270px;
}

#tl8{
    right: 100px;
    top: 90px;
}

#p1 {
    right:40px;
    top: 25px;
    animation: shake 20s linear infinite;
}

@keyframes shake {
    0% {
        transform: translateX(-20px);
    }
    28% {
        transform: translateX(10px);
    }
    30% {
        transform: translateX(-10px);
    }
    50% {
        transform: translateX(20px);
    }
    68% {
        transform: translateX(-10px);
    }
    70% {
        transform: translateX(10px);
    }
    100% {
        transform: translateX(-20px);
    }
}

#p2 {
    right: 20px;
    top: 100px;
    width: 50px;
    height: 50px;
    animation: rotate 15s linear infinite;
}

#p12 {
    right: 150px;
    top: 50px;
    z-index: 1;
    width: 30px;
    height: 30px;
    animation: rotate 25s linear infinite;
}

#p3 {
    left: 250px;
    top: 120px;
    width: 60px;
    height: 60px;
    animation: ship 25s linear infinite;
}

@keyframes ship {
    0%{
        opacity: 0;
        transform: translateX(0px) translateY(0px);
        scale: 0;
    }

    25%{
        opacity: 100;
        transform: translateX(350px) translateY(40px);
        scale: 1;
    }
    45%{
        opacity: 10;
        transform: translateX(350px) translateY(-20px);
        scale: 1;
    }
    50%{
        opacity: 0;
        transform: translateX(-350px) translateY(180px);
        scale: 0;
    }
    70%{
        opacity: 10;
        transform: translateX(350px) translateY(-20px);
        scale: 1;
    }
    75%{
        opacity: 100;
        transform: translateX(350px) translateY(40px);
        scale: 0;
    }
    100%{
        opacity:0;
        transform: translateX(0px) translateY(0px);
        scale: 0;
    }
}

#p4 {
    left: 250px;
    top: 120px;
    width: 60px;
    height: 60px;
}

#p6 {
    left: 225px;
    top: 120px;
    width: 20px;
    height: 20px;
    animation: rotate 20s linear infinite;
}

#p7 {
    left: 280px;
    top: 150px;
    width: 30px;
    height: 30px;
    animation: rotate 35s linear infinite;

}

#p5 {
    left: 250px;
    top: 500px;
    animation: spin 80s linear infinite;
}

#p8 {
    width: 140px;
    height: 140px;
    top: 165px;
    right: 335px;
    animation: spin 4s linear infinite;
}

#p11 {
    right: 200px;
    bottom: 180px;
    animation: spin-move 20s linear infinite;

}

@keyframes spin {
    0% {
        transform: rotateZ(360deg);
    }
}

@keyframes spin-move {
    0% {
        transform: rotateZ(180deg) translateY(-40px);
    }
    50% {
        transform: rotateZ(0deg) translateY(40px);
    }
    100% {
        transform: rotateZ(-180deg) translateY(-40px);
    }
}

@keyframes rotate {
    0% {
        transform: translateY(-10px) translateX(-10px);
    }
    25% {
        transform: translateY(-10px) translateX(10px);
    }
    50% {
        transform: translateY(10px) translateX(-10px);
    }
    75% {
        transform: translateY(-10px) translateX(10px);
    }
    100% {
        transform: translateY(-10px) translateX(-10px);
    }
}
.p-card {
    position: absolute;
}

/************ Footer Css ************/
.footer {
    padding: 50px 0 50px 0;
    background-image: url('./assets/img/footer-bg.png');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.newsletter-bx {
    background: #FFFFFF;
    border-radius: 55px;
    color: #121212;
    padding: 85px 125px;
    margin-bottom: 80px;
    margin-top: -122px;
}

.newsletter-bx h3 {
    font-weight: 700;
    letter-spacing: 0.5px;
    line-height: 1.2em;
}

.new-email-bx {
    background: #fff;
    padding: 5px;
    border-radius: 20px;
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
}

.new-email-bx::before {
    content: "";
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    border-radius: 20px;
    position: absolute;
    z-index: -1;
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
}

.new-email-bx::after {
    content: "";
    background: #fff;
    border-radius: 20px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.new-email-bx input {
    width: 100%;
    color: #121212;
    font-weight: 500;
    background: transparent;
    border: 0;
    padding: 0 15px;
}

.new-email-bx button {
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    padding: 20px 65px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.5px;
    border-radius: 18px;
}

.footer img {
    width: 26%;
}

.footer p {
    font-weight: 400;
    font-size: 14px;
    color: #B8B8B8;
    letter-spacing: 0.5px;
    margin-top: 20px;
}

#footer_thanks{
    font-size: 10px;
    color: #B8B8B8;
}
